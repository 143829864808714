<template>
  <div>
    <div class="mx-4 my-4 pa-4 elevation-10 rounded-lg">
      <v-flex class="d-flex">
        <v-autocomplete
            v-model="searchFdivision"
            :items="itemsFdivision"
            item-value="kode1"
            item-text="description"
            outlined
            dense
            small-chips
            label="Cabang/Division"
        ></v-autocomplete>

        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Filter dengan kode produk"
            hide-details
            class="mx-1"
            dense
            outlined
        ></v-text-field>

      </v-flex>

    </div>
    <div class="mx-4 my-4 pa-2 elevation-10 rounded-lg">
      <v-row>
        <v-col cols="12">
          <v-data-table
              :headers="headersStockCoverDay"
              :items="computedItemsWarningStockWithStockCoverDay"
              :search="search"
              :page.sync="currentPage"
              :items-per-page = "pageSize"
              dense
          >
            <template v-slot:[`item.number`]="{ index }">
              {{ (index + ((currentPage-1)*pageSize) +1 )}}
            </template>
            <template v-slot:[`item.fmaterialPcode`]="{ item }">
              <div>{{ item.fmaterialPcode }}</div>
              <div class="caption grey--text">
                {{ lookupFMaterial(item.fmaterialBean).pname }}
              </div>
            </template>

            <template v-slot:[`item.saldoStockInPcs`]="{ item }">
              <div>{{ item.saldoStockInPcs }}</div>
              <div class="caption grey--text">
                {{ lookupUom1234StringUom(lookupFMaterial(item.fmaterialBean), Math.abs(item.saldoStockInPcs)) }}
              </div>
            </template>
            <template v-slot:[`item.salesAvgQtyInPcs`]="{ item }">
              <div>{{ Math.round(item.salesAvgQtyInPcs) }}</div>
              <div class="caption grey--text">
                {{ lookupUom1234StringUom(lookupFMaterial(item.fmaterialBean), Math.abs(item.salesAvgQtyInPcs)) }}
              </div>
            </template>
            <template v-slot:[`item.stockMinimumInPcs`]="{ item }">
              <div>{{ Math.round(item.stockMinimumInPcs) }}</div>
              <div class="caption grey--text">
                {{ lookupUom1234StringUom(lookupFMaterial(item.fmaterialBean), Math.abs(item.stockMinimumInPcs)) }}
              </div>
            </template>

            <template v-slot:[`item.warningStockInPcs`]="{ item }">
              <div >{{ item.warningStockInPcs }}</div>
              <div class="caption grey--text red--text">
                {{ lookupUom1234StringUom(lookupFMaterial(item.fmaterialBean), Math.abs(item.warningStockInPcs)) }}
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" class="caption">
          <div class="font-weight-bold ml-2 green--text">
            Umur Stock dengan Metode FIFO Otomatis:
          </div>
        </v-col>
      </v-row>
    </div>

  </div>
</template>

<script>
export default {
  name: "UmurStockTable",
  data(){
    return{
      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      search : '',
      searchFdivision: '',

      headersStockCoverDay: [
        {
          text: 'No',
          value: 'number',
          width: '5%',
          sortable: false
        },
        { text: 'Division', value: 'fdivisionKode1', width: '8%' },
        { text: 'Warehouse', value: 'fwarehouseKode1', width: '8%' },
        { text: 'Produk', value: 'fmaterialPcode', width: '15%' },
        { text: '0-30', value: 'saldoStockInPcs', width: '10%'  },
        { text: '31-60', value: 'salesAvgQtyInPcs', width: '10%'  },
        { text: '61-90', value: 'stockMinimumInPcs', width: '10%'  },
        { text: '>90', value: 'warningStockInPcs', width: '10%'  }
      ],

      paramSalesHistoryDays: 56, //8x7hari
      paramLeadTime: 21, //21 hari

      koefisienLeadTime: 1.75,

      itemsFmaterial: [],
      itemsFdivision: [],
      itemsSaldoStockPerDiv: [],
      itemsSalesAvg: [],
      itemsSalesWithDaysAvg: [],

    }
  },
  computed:{
    computedItemsWarningStockWithStockCoverDay(){
      let newItems = []
      let index = 0
      this.itemsFdivision.filter(x =>x.kode1 != '00').forEach(itemFdivision => {
        this.itemsFmaterial.forEach(itemFmaterial => {

          let stocks =  this.itemsSaldoStockPerDiv.filter(x => x.fmaterialBean == itemFmaterial.id && x.fdivisionBean == itemFdivision.id)

          let saldoStockInPcsQty = 0
          if (stocks.length>0){
            saldoStockInPcsQty = stocks[0].sumResult
            // console.log(`${itemFmaterial.pcode} >> ${saldoStockInPcsQty}`)
          }
          let salesAvgQtyInPcss =  this.itemsSalesAvg.filter(x => x.fmaterialBean == itemFmaterial.id && x.fdivisionBean == itemFdivision.id)
          let salesAvgQtyInPcsQty = 0
          if (salesAvgQtyInPcss.length>0){
            salesAvgQtyInPcsQty = salesAvgQtyInPcss[0].sumResult
            // console.log(`${itemFmaterial.pcode} >> ${salesAvgQtyInPcsQty}`)
          }

          /**
           * 21 Hari
           * @type {number}
           */
          let stockMinimumInPcsQty = salesAvgQtyInPcsQty * this.paramLeadTime
          let warningStockInPcsQty = saldoStockInPcsQty - stockMinimumInPcsQty

          let newItem = {id: index++, fdivisionBean: itemFdivision.id, fdivisionKode1: itemFdivision.kode1,
            fmaterialBean: itemFmaterial.id, fmaterialPcode: itemFmaterial.pcode, saldoStockInPcs: saldoStockInPcsQty, salesAvgQtyInPcs: salesAvgQtyInPcsQty.toFixed(1),
            stockMinimumInPcs: stockMinimumInPcsQty.toFixed(1), warningStockInPcs: Math.round(warningStockInPcsQty) }

          // if (salesAvgQtyInPcsQty>0){
          // if (saldoStockInPcsQty>0){
          if (warningStockInPcsQty<0 && saldoStockInPcsQty>0){
            newItems.push(newItem)
          }

        })
      })

      return newItems.filter(x => x.fdivisionKode1.includes(this.searchFdivision)).sort((a, b) => (a.warningStockInPcs > b.warningStockInPcs)? 1: -1)
    },

  }

}
</script>

<style scoped>

</style>