<template>
  <UmurStockTable></UmurStockTable>
</template>

<script>
import UmurStockTable from "@/components/inventory/umur-stock/UmurStockTable";
export default {
  name: "UmurStockView",
  components: {UmurStockTable}
}
</script>

<style scoped>

</style>